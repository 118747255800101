import { DocumentTextIcon, PencilIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { OrderStatusResponse } from 'src/api';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import useOrder from 'src/api/hooks/queries/useOrder';
import Button from 'src/components/Button';
import HyperLink from 'src/components/common/HyperLink';
import Tooltip from 'src/components/common/Tooltip';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import Loader from 'src/components/utils/Loader';
import useWithConfirmationPopup from 'src/hooks/popups/useWithConfirmationPopup';
import useGetIsModuleValid from 'src/hooks/useGetIsModuleValid';
import useIsCurrentUserAllowedToEdit from 'src/hooks/useIsCurrentUserAllowedToEdit';
import { Modules } from 'src/types/commonTypes';
import useDeleteOrder from '../../api/hooks/mutations/order/useDeleteOrder';
import UpdateServicePopup from '../OrdersPage/UpdateServicePopup';
import ServiceAlerts from './components/ServiceAlerts';
import { getCanEdit, getCanFinish, getHasPdfLink } from './helpers';
import ServicePreview from './ServicePreview';
import PageCard from 'src/components/PageCard';

const ServicePreviewPage: React.FC = () => {
  const getIsModuleValid = useGetIsModuleValid();
  const isCurrentUserAllowedToEdit = useIsCurrentUserAllowedToEdit();

  const orderId = Number(useParams()?.orderId);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { data: currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser();
  const withConfirmationPopup = useWithConfirmationPopup();
  const { data, isLoading } = useOrder(orderId);
  const deleteOrder = useDeleteOrder();
  const navigate = useNavigate();
  const canFinish = useMemo(() => !!data && getCanFinish(data), [data]);
  const canEdit = useMemo(() => !!data && getCanEdit(data), [data]);
  const hasPdfLink = !!data && getHasPdfLink(data);

  const onDelete = async (): Promise<void> => {
    try {
      await deleteOrder.mutateAsync({ orderId });
      navigate(-1);
    } catch (e) {
      //
    }
  };

  const onDeleteButtonClick = withConfirmationPopup({
    onOk: onDelete,
    title: (
      <FormattedMessage
        id='app.order_detail_page.delete_order_popup.title'
        values={{
          value: data?.orderNumber,
        }}
      />
    ),
  });

  if (isLoading || isLoadingCurrentUser) return <Loader variant='global' />;
  if (!data) return <Navigate to='/404' />;
  if (!currentUser) return <Navigate to='/500' />;
  if (!getIsModuleValid(Modules.SERVICES)) return <Navigate to='/user' />;

  const shouldHideDeleteButton = OrderStatusResponse.name.INVOICED === data.status || !isCurrentUserAllowedToEdit;

  // const shouldHideEditButton =
  //   (
  //     [
  //       OrderStatusResponse.name.COMPLETED,
  //       OrderStatusResponse.name.DELETED,
  //       OrderStatusResponse.name.INVOICED,
  //     ] as unknown[]
  //   ).includes(data?.status) || !isCurrentUserAllowedToEdit;

  const shouldShowAlerts = ([OrderStatusResponse.name.PLANNED, OrderStatusResponse.name.ROUTINE] as unknown[]).includes(
    data?.status,
  );

  return (
    <>
      <PageContentHeader
        className='mb-12'
        title={
          <div className='flex items-center'>
            <FormattedMessage id='app.order_detail_page.title' values={{ value: data.orderNumber }} />
            {!shouldHideDeleteButton && (
              <Button variant='cta' size='small' onClick={onDeleteButtonClick}>
                <TrashIcon className='h-6 mr-1' />
              </Button>
            )}
          </div>
        }
        primaryButtonText={
          <HyperLink noStyles forceExternal href={data.orderUrl} target='_blank' className='flex gap-x-1 text-inherit'>
            <PencilSquareIcon className='h-6' />
            <FormattedMessage id='app.buttons.finish' />
          </HyperLink>
        }
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
          </>
        }
        onSecondaryButtonClick={() => navigate('/order')}
        hidePrimaryButton={!canFinish}
      />
      <div className='flex flex-col'>
        {shouldShowAlerts && <ServiceAlerts data={data} className='mb-12' />}
        <PageCard>
          <ServicePreview
            data={data}
            extraTitle={
              <>
                {canEdit && (
                  <Button variant='cta' size='small' onClick={() => setIsPopupOpen(true)}>
                    <Tooltip text={<FormattedMessage id='app.buttons.edit' />}>
                      <PencilIcon className='h--6 w-6' />
                    </Tooltip>
                  </Button>
                )}
                {hasPdfLink && (
                  <HyperLink
                    forceExternal
                    target='_blank'
                    href={`${window?.location.host}/api/v1/orders/${data.id}/service-list`}
                  >
                    <DocumentTextIcon className='h--6 w-6' />
                  </HyperLink>
                )}
              </>
            }
          />
        </PageCard>
      </div>

      <UpdateServicePopup orderId={orderId} open={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
    </>
  );
};

export default ServicePreviewPage;
