import { FC, ReactNode } from 'react';
import { OrderListItemResponse } from 'src/api';
import OrderInfoCard from 'src/components/OrderInfoCard';
import OrderDayCardsDivider from '../OrderDayCardsDivider';
import { twMerge } from 'tailwind-merge';
import { Disclosure, Transition } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';

export type OrderDayCardPsrops = {
  className?: string;
  items: OrderListItemResponse[];
  label?: ReactNode;
  defaultOpen?: boolean;
};

const OrderDayCards: FC<OrderDayCardPsrops> = ({ defaultOpen, className, items, label }) => {
  return (
    <Disclosure defaultOpen={defaultOpen ?? !!items.length}>
      <Disclosure.Button>
        <OrderDayCardsDivider label={label} />
      </Disclosure.Button>

      <Transition
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'
      >
        <Disclosure.Panel className={twMerge('flex flex-col', className)}>
          {items.length > 0 ? (
            <div className='grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3'>
              {items.map((order) => (
                <OrderInfoCard key={order.id} order={order} />
              ))}
            </div>
          ) : (
            <FormattedMessage id='app.toast.no_data' />
          )}
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
};

export default OrderDayCards;
