import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { OrderListItemResponse } from 'src/api';
import TechnicianAvatarsStack from 'src/components/TechnicianAvatarsStack/TechnicianAvatarsStack';

export type TechniciansCellProps = {
  info: CellContext<OrderListItemResponse, unknown>;
};

const TechniciansCell: FC<TechniciansCellProps> = ({ info }) => {
  const names = (info.row.original.technicians?.map((i) => i.profile?.name).filter(Boolean) ?? []) as string[];

  return (
    <div className='flex items-center gap-1 relative flex-nowrap'>
      <TechnicianAvatarsStack names={names} />
      <span className='whitespace-nowrap text-ellipsis overflow-hidden'>{names.join(', ')}</span>
    </div>
  );
};

export default TechniciansCell;
