import { OrderStatusResponse } from 'src/api';
import { match } from 'ts-pattern';

export const getStatusBackgroundClassNames = (name: OrderStatusResponse.name): string =>
  match(name)
    .with(OrderStatusResponse.name.PLANNED, () => 'bg-yellow-100')
    .with(OrderStatusResponse.name.INVOICED, () => 'bg-blue-50')
    .with(OrderStatusResponse.name.COMPLETED, () => 'bg-lime-50')
    .with(OrderStatusResponse.name.IN_PROGRESS, () => 'bg-orange-100')
    .with(OrderStatusResponse.name.UNSUCCESSFUL, () => 'bg-red-200')
    .with(OrderStatusResponse.name.DELETED, () => 'bg-neutral-300')
    .with(OrderStatusResponse.name.ROUTINE, () => 'bg-purple-100')
    .exhaustive();

export const getStatusBorderClassNames = (name: OrderStatusResponse.name): string =>
  match(name)
    .with(OrderStatusResponse.name.PLANNED, () => 'border-yellow-400')
    .with(OrderStatusResponse.name.INVOICED, () => 'border-blue-300')
    .with(OrderStatusResponse.name.COMPLETED, () => 'border-lime-400')
    .with(OrderStatusResponse.name.IN_PROGRESS, () => 'border-orange-400')
    .with(OrderStatusResponse.name.UNSUCCESSFUL, () => 'border-red-500')
    .with(OrderStatusResponse.name.DELETED, () => 'border-neutral-600')
    .with(OrderStatusResponse.name.ROUTINE, () => 'border-purple-400')
    .exhaustive();

export const getStatusTextClassNames = (name: OrderStatusResponse.name): string =>
  match(name)
    .with(OrderStatusResponse.name.PLANNED, () => 'text-yellow-700')
    .with(OrderStatusResponse.name.INVOICED, () => 'text-blue-500')
    .with(OrderStatusResponse.name.COMPLETED, () => 'text-lime-600')
    .with(OrderStatusResponse.name.IN_PROGRESS, () => 'text-orange-700')
    .with(OrderStatusResponse.name.UNSUCCESSFUL, () => 'text-red-700')
    .with(OrderStatusResponse.name.DELETED, () => 'text-neutral-900')
    .with(OrderStatusResponse.name.ROUTINE, () => 'text-purple-700')
    .exhaustive();

export const getSolidStatusBackgroundClassNames = (name: OrderStatusResponse.name): string =>
  match(name)
    .with(OrderStatusResponse.name.PLANNED, () => 'bg-yellow-400')
    .with(OrderStatusResponse.name.INVOICED, () => 'bg-blue-300')
    .with(OrderStatusResponse.name.COMPLETED, () => 'bg-lime-400')
    .with(OrderStatusResponse.name.IN_PROGRESS, () => 'bg-orange-400')
    .with(OrderStatusResponse.name.UNSUCCESSFUL, () => 'bg-red-500')
    .with(OrderStatusResponse.name.DELETED, () => 'bg-neutral-600')
    .with(OrderStatusResponse.name.ROUTINE, () => 'bg-purple-400')
    .exhaustive();
