import React, { useMemo } from 'react';
import { format, isSameDay, parseISO, addDays, startOfWeek } from 'date-fns';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { twMerge } from 'tailwind-merge';
import ContributionDay from './ContributionDay';

type ContributorDay = {
  day: string;
  contributionsCount: number;
};

type Contributor = {
  name: string;
  days: ContributorDay[];
};

type ContributionGraphProps = {
  contributors: Contributor[];
  className?: string;
};

const ContributionGraph: React.FC<ContributionGraphProps> = ({ contributors, className }) => {
  const { data: locale } = useUserFnsLocale();

  const weekDays = useMemo(() => {
    const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

    const days = Array(7)
      .fill(0)
      .map((_, i) => addDays(firstDayOfWeek, i));

    return days;
  }, []);

  return (
    <div className={twMerge('w-max grid grid-cols-[auto_repeat(7,auto)] gap-0.5 items-center', className)}>
      <div></div> {/* Empty top-left corner */}
      {weekDays.map((day) => (
        <div key={day.getUTCDate()} className='text-center text-sm pb-2'>
          {format(day, 'E', { locale }).slice(0, 2)}
        </div>
      ))}
      {contributors.map((technician) => (
        <React.Fragment key={technician.name}>
          <div className='pr-2 text-xs'>{technician.name}</div>

          {weekDays.map((day) => {
            const techDay = technician.days.find((d) => isSameDay(parseISO(d.day), day));
            const ordersCount = techDay ? techDay.contributionsCount : 0;

            return <ContributionDay key={day.toISOString()} contributionsCount={ordersCount} />;
          })}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ContributionGraph;
