import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderStatusResponse } from 'src/api';
import { twMerge } from 'tailwind-merge';
import { getStatusBackgroundClassNames, getStatusBorderClassNames, getStatusTextClassNames } from './helpers';

export type StatusLabelProps = {
  name: OrderStatusResponse.name;
  className?: string;
};

const StatusLabel: React.FC<StatusLabelProps> = ({ name, className }) => {
  return (
    <span
      className={twMerge(
        'flex items-center justify-center py-2.5 px-2 rounded text-white text-sm font-normal border box-border',
        getStatusBackgroundClassNames(name),
        getStatusTextClassNames(name),
        getStatusBorderClassNames(name),
        className,
      )}
    >
      <FormattedMessage id={`app.order_status.${name?.toLowerCase() as Lowercase<OrderStatusResponse.name>}`} />
    </span>
  );
};

export default StatusLabel;
