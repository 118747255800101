import { Cell, flexRender } from '@tanstack/react-table';
import { PropsWithChildren } from 'react';

export type TableCellProps<T> = {
  cell: Cell<T, unknown>;
  onClick?: () => void;
};

const TableCell = <T extends { id: number }>({ cell, onClick }: PropsWithChildren<TableCellProps<T>>): JSX.Element => {
  const { size, maxSize } = cell.column.columnDef;

  return (
    <td
      style={{ width: size, maxWidth: maxSize }}
      onClick={onClick}
      className='py-3 px-2 min-h-[85px] text-[1.075rem]  whitespace-normal'
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

export default TableCell;
