import clsx from 'clsx';
import { CSSProperties, forwardRef, memo } from 'react';
import { OrderStatusResponse } from 'src/api';
import { GridEvent } from '../../types';
import { getSolidStatusBackgroundClassNames } from 'src/components/StatusLabel/helpers';

export const createGridStyles = (
  row: number,
  rowSpan: number,
  col: number,
  colSpan: number,
): {
  gridColumn: string;
  gridRow: string;
} => ({
  gridColumn: `${col} / span ${colSpan}`,
  gridRow: `${row} / span ${rowSpan}`,
});

export type EventProps = GridEvent & {
  style?: CSSProperties;
  row: number;
  rowSpan: number;
  col: number;
  colSpan: number;
  onClick?: (e: React.MouseEvent) => void;
};

const Event = forwardRef<HTMLLIElement, EventProps>(({ id, data, row, rowSpan, col, colSpan, style, onClick }, ref) => {
  const { orderNumber, status, companyName } = data;

  return (
    <li
      data-cy={`event-${id}`}
      className='relative mt-px flex overflow-hidden'
      style={{
        ...style,
        ...createGridStyles(row, rowSpan, col, colSpan),
      }}
      ref={ref}
    >
      <div
        onClick={onClick}
        className={clsx(
          'cursor-pointer group absolute inset-1 flex flex-col rounded-lg p-2 text-xs overflow-hidden leading-5',
          status?.name && getSolidStatusBackgroundClassNames(status.name as OrderStatusResponse.name),
        )}
      >
        <p className='font-semibold'>{orderNumber}</p>
        <p className='overflow-hidden text-ellipsis whitespace-nowrap'>{companyName}</p>
      </div>
    </li>
  );
});

export default memo(Event);
