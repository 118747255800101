import { getWeek, parseISO, isSameDay, eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';
import { useMemo } from 'react';
import { OrderListItemResponse } from 'src/api';
import useAllTechniciansWeekOrders from 'src/api/hooks/queries/useAllTechniciansWeekOrders';

export type WeekOrderGroups = Array<{ day: Date; items: OrderListItemResponse[] }>;

type UseGrouppedWeekOrdersReturn = {
  data: WeekOrderGroups;
  isLoading: boolean;
};

const useGrouppedWeekOrders = (dayOfTheWeek: Date): UseGrouppedWeekOrdersReturn => {
  const { data: orders, isLoading } = useAllTechniciansWeekOrders(
    dayOfTheWeek.getFullYear(),
    getWeek(dayOfTheWeek, { weekStartsOn: 1 }) - 1,
  );

  const grouppedOrders = useMemo<WeekOrderGroups>(() => {
    const days = eachDayOfInterval({
      start: startOfWeek(dayOfTheWeek, { weekStartsOn: 1 }),
      end: endOfWeek(dayOfTheWeek, { weekStartsOn: 1 }),
    });

    if (!orders) {
      return days.map((day) => ({ day, items: [] }));
    }

    const ordersWithDates = orders.filter((order) => !!order.datetime && !!order.endDatetime) as Array<
      Omit<OrderListItemResponse, 'datetime' | 'endDatetime'> & { datetime: string; endDatetime: string }
    >;

    return days.map((day) => ({
      day,
      items: ordersWithDates.filter((order) => {
        const orderDate = parseISO(order.datetime);
        return isSameDay(orderDate, day);
      }),
    }));
  }, [orders]);

  return { data: grouppedOrders, isLoading };
};

export default useGrouppedWeekOrders;
